import React from 'react';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import banner from '../../assets/images/banner.jpg';
import calculaDiferencaData from '../../utils/calculaDiferencaData';
import api from '../../services/api';

async function getPosts(page) {
  const { data } = await api.get(`/posts?page=${page}`);

  return data;
}

export default function Blog() {
  const location = useLocation();
  const query = String(location?.search)
    .replace('?', '')
    .split('&')
    .reduce((a, b) => {
      const [key, value] = b.split('=');

      a[key] = value;

      return a;
    }, {});
  const { data } = useQuery({
    queryKey: [`posts?page=${query?.page || 1}`],
    queryFn: () => getPosts(query?.page || 1),
  });

  return (
    <>
      <div
        className="blog-banner"
        style={{
          backgroundImage: `url(${banner})`,
        }}
      >
        <div className="blog-banner-text">
          <h1 className="text-center text-white">Blog UneoGreen Bank</h1>
          <h4 className="text-white text-center p-3">
            Somos mais do que um banco, somos uma força impulsionadora para um
            mundo mais verde.
          </h4>
        </div>
      </div>
      <div className="container mt-5">
        <Row>
          {(data?.items || []).map((m) => (
            <Col lg={4} className="mb-5" key={m}>
              <Link to={`/${m?.url}`} className="btn-none">
                <div className="blog-card">
                  <div className="blog-card-img">
                    <img alt="text" src={m.img_url} />
                  </div>
                  <div className="blog-card-body">
                    <div className="blog-card-tag">
                      <div>
                        <Link
                          to={`/tag/${m?.BlogCategorium?.id}-${m?.BlogCategorium?.titulo}`}
                        >
                          {m?.BlogCategorium?.titulo}
                        </Link>
                      </div>
                      <div>
                        <p className="text-muted mb-0">
                          {calculaDiferencaData(m?.createdAt)}
                        </p>
                      </div>
                    </div>
                    <div className="blog-card-title">{m.titulo}</div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="d-flex-wrap justify-content-center align-items-center">
          {Number(data?.page) > 1 && (
            <Link className="mb-1 mr-3" to={`?page=${Number(data?.page) - 1}`}>
              <FaArrowLeft color="#000" />
            </Link>
          )}{' '}
          Página {data?.page} de {data?.pages}
          {Number(data?.page) + 1 < Number(data?.pages) && (
            <Link className="mb-1 ml-3" to={`?page=${Number(data?.page) + 1}`}>
              <FaArrowRight color="#000" />
            </Link>
          )}{' '}
        </div>
      </div>
    </>
  );
}
