import React, { useEffect } from 'react';
import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';

function Blog({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <>
      <Navbar fixed="top" className="blog-header">
        <NavbarBrand href="/">
          <img
            src={logo}
            alt="logo uneogreenbank"
            className="object-fit"
            height={34}
            width={200}
          />
        </NavbarBrand>
        <Nav>
          <NavItem>
            <a
              href="https://uneogreenbank.com.br/"
              target="_blank"
              rel="noreferrer"
              className="text-black fw-bold"
            >
              Abra sua conta
            </a>
          </NavItem>
        </Nav>
      </Navbar>
      <main className="blog-main">
        {children}
        <div className="container  mt-3 mb-3">
          <hr />
          <div className="text-black fw-semibold text-center">
            Made with <span className="text-danger">❤</span> by{' '}
            <a href="https://xcoding.com.br">Xcoding</a>
          </div>
        </div>
      </main>
    </>
  );
}

export default Blog;
