export default function calculaDiferencaData(data) {
  const dataFornecida = new Date(data);
  const dataAtual = new Date();

  const diffEmMilissegundos = dataAtual - dataFornecida;

  const segundos = Math.floor(diffEmMilissegundos / 1000);
  const minutos = Math.floor(segundos / 60);
  const horas = Math.floor(minutos / 60);
  const dias = Math.floor(horas / 24);
  const meses = Math.floor(dias / 30);
  const anos = Math.floor(meses / 12);

  if (anos > 0) {
    return `há ${anos} ${anos === 1 ? 'ano' : 'anos'}.`;
  }
  if (meses > 0) {
    return `há ${meses} ${meses === 1 ? 'mês' : 'meses'}.`;
  }
  if (dias > 0) {
    return `há ${dias} ${dias === 1 ? 'dia' : 'dias'}.`;
  }
  if (horas > 0) {
    return `há ${horas} ${horas === 1 ? 'hora' : 'horas'}.`;
  }
  return `há ${minutos} ${minutos === 1 ? 'minuto' : 'minutos'}.`;
}
