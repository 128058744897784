import React from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import Home from '../pages/Home/index';
import Tag from '../pages/Home/tag';
import Post from '../pages/Home/post';
import Layout from '../layouts/blog';

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Layout>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/tag/:id" exact component={Tag} />
          <Route path="/:id" exact component={Post} />

          <Redirect to="/" />
        </Switch>
      </Layout>
    </Switch>
  </BrowserRouter>
);

export default Routes;
