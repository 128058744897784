import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import calculaDiferencaData from '../../utils/calculaDiferencaData';
import api from '../../services/api';

async function getPosts(categoria, page) {
  const { data } = await api.get(
    `/posts?id_blog_categoria=${categoria}&page=${page}`
  );

  return data;
}

export default function PostCategoria() {
  const params = useParams();
  const location = useLocation();
  const query = String(location?.search)
    .replace('?', '')
    .split('&')
    .reduce((a, b) => {
      const [key, value] = b.split('=');

      a[key] = value;

      return a;
    }, {});
  const titulo = String((params || {})?.id).split('-');
  const { data } = useQuery({
    queryKey: [`posts:${titulo[0]}?page=${query?.page || 1}`],
    queryFn: () => getPosts(titulo[0], query?.page || 1),
  });

  if (data?.categoria?.titulo) {
    document.title = `${data?.categoria?.titulo} - UneoGreen Bank`;
  }

  return (
    <>
      <div className="blog-banner-sm">
        <div className="blog-banner-text">
          <h1 className="text-center text-white">
            {data?.categoria?.titulo || titulo[1]}
          </h1>
        </div>
      </div>
      <div className="bg-gray pt-3 pb-1">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="text-black">
                  Blog
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-black fw-bold"
                aria-current="page"
              >
                {data?.categoria?.titulo || titulo[1]}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container mt-5">
        <Row>
          {(data?.items || []).map((m) => (
            <Col lg={4} className="mb-5" key={m}>
              <Link to={`/${m?.url}`} className="btn-none">
                <div className="blog-card">
                  <div className="blog-card-img">
                    <img alt="text" src={m.img_url} />
                  </div>
                  <div className="blog-card-body">
                    <div className="blog-card-tag">
                      <div>
                        <Link
                          to={`/tag/${m?.BlogCategorium?.id}-${m?.BlogCategorium?.titulo}`}
                        >
                          {m?.BlogCategorium?.titulo}
                        </Link>
                      </div>
                      <div>
                        <p className="text-muted mb-0">
                          {calculaDiferencaData(m?.createdAt)}
                        </p>
                      </div>
                    </div>
                    <div className="blog-card-title">{m.titulo}</div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="d-flex-wrap justify-content-center align-items-center">
          {Number(data?.page) > 1 && (
            <Link className="mb-1 mr-3" to={`?page=${Number(data?.page) - 1}`}>
              <FaArrowLeft color="#000" />
            </Link>
          )}{' '}
          Página {data?.page} de {data?.pages}
          {Number(data?.page) + 1 < Number(data?.pages) && (
            <Link className="mb-1 ml-3" to={`?page=${Number(data?.page) + 1}`}>
              <FaArrowRight color="#000" />
            </Link>
          )}{' '}
        </div>
      </div>
    </>
  );
}
