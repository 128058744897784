import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import calculaDiferencaData from '../../utils/calculaDiferencaData';
import api from '../../services/api';

async function getPosts(id) {
  const { data } = await api.get(`/posts/${id}`);

  return data;
}
export default function Post() {
  const params = useParams();
  const id = (params || {})?.id;

  const { data } = useQuery({
    queryKey: [`post:${id}`],
    queryFn: () => getPosts(id),
  });

  if (data?.post?.titulo) {
    document.title = `${data?.post?.titulo} - UneoGreen Bank`;
  }

  return (
    <>
      <div className="blog-banner-post-header">
        <div
          className="blog-banner-post"
          style={{
            backgroundImage: `url("${data?.post?.img_url}")`,
          }}
        >
          <div className="container">
            <Row>
              <Col lg={6}>
                <span className="badge-post small">
                  {data?.post?.BlogCategorium?.titulo}
                </span>
                <h1 className="text-white mt-3 text-post-title">
                  {data?.post?.titulo}
                </h1>
                <p className="text-white font-500 mt-3">
                  Publicado em{' '}
                  {new Date(data?.post?.createdAt).toLocaleDateString('pt-BR')}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="bg-gray pt-3 pb-1">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" className="text-black">
                  Blog
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`/tag/${data?.post?.BlogCategorium?.id}-${data?.post?.BlogCategorium?.titulo}`}
                  className="text-black"
                >
                  {data?.post?.BlogCategorium?.titulo}
                </Link>
              </li>
              <li
                className="breadcrumb-item active text-black fw-bold"
                aria-current="page"
              >
                {data?.post?.titulo}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div
        className="container mt-5"
        dangerouslySetInnerHTML={{
          __html: data?.post?.html,
        }}
      />

      {data?.relacionados?.length > 0 && (
        <div className="container mt-3">
          <h2 className="text-black mt-5 mb-5">Posts relacionados</h2>
          <Row className="mt-3">
            {(data?.relacionados || []).map((m) => (
              <Col lg={4} className="mb-5" key={m}>
                <Link to={`/${m?.url}`} className="btn-none">
                  <div className="blog-card">
                    <div className="blog-card-img">
                      <img alt="text" src={m.img_url} />
                    </div>
                    <div className="blog-card-body">
                      <div className="blog-card-tag">
                        <div>
                          <Link
                            to={`/tag/${m?.BlogCategorium?.id}-${m?.BlogCategorium?.titulo}`}
                          >
                            {m?.BlogCategorium?.titulo}
                          </Link>
                        </div>
                        <div>
                          <p className="text-muted mb-0">
                            {calculaDiferencaData(m?.createdAt)}
                          </p>
                        </div>
                      </div>
                      <div className="blog-card-title">{m.titulo}</div>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
}
